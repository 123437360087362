import getHtml from './getHtml';
import setHtml from './setHtml';
import isString from '../../helpers/lang/isString';

/*
 * Set/Get Html from a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {string} htm
 *
 * @returns {string|null|undefined}
 */
export default function (node, htm) {
    return (isString(htm) ? setHtml(node, htm) : getHtml(node));
}
