import select from '../select/select';
import isString from '../../helpers/lang/isString';

/*
 * Set Text for a Node.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {string} txt
 */
export default function (node, txt) {

    // Select matching node
    node = select(node);

    // DOM node exists, set text value
    if (node && isString(txt)) {
        node.textContent = txt;
    }
}
