import isHidden from './isHidden';
import showHide from '../.internal/attributes/showHide';
import selectAll from '../select/selectAll';
import each from '../../helpers/collection/each';

/*
 * Show all matching Nodes
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string|Array} nodes
 * @param {Node} context
 */
export default function (nodes, context) {

    // Show all chosen nodes
    each(selectAll(nodes, context), node => {

        // Node is hidden, show it
        if (isHidden(node)) {
            showHide(node, true);
        }
    });
}
