import getAttrStyle from './.internal/getAttrStyle';
import getDimensionComputed from './.internal/getDimensionComputed';
import getDimensionStyle from './.internal/getDimensionStyle';
import hide from '../../../fn/attributes/hide';
import data from '../../../fn/data/data';
import get from '../../../helpers/object/get';
import Tweener from '../../../utilities/Tweener';

/*
 * Slide Up animation for a node. Similar to the jQuery slideUp() function. This
 * is part of the animate methods and returns a Promise. animate() must be called
 * prior to calling this function.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 * @param {Object} nodeDataAnimate
 * @param {boolean} animating
 * @param {number} speed
 * @param {function} ease
 * @param {number} progress
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (node, nodeDataAnimate, animating, animation, speed, ease, progress, options) {
    return new Promise(resolve => {


        // Node is already animating, kill previous animation
        if (animating) {
            Tweener.kill(node, false, {
                height: true,
                paddingTop: true,
                paddingBottom: true,
                marginTop: true,
                marginBottom: true
            });
        }

        // Dimension
        const dimensionComputed = getDimensionComputed(node);
        const dimensionStyle = getDimensionStyle(node);

        // Current data animate
        let dataAnimate = {
            animating: true,
            animation: 'slideUp',
            dimensionStyle: get(nodeDataAnimate, 'dimensionStyle', dimensionStyle),
            dimensionOriginal: animating ? get(nodeDataAnimate, 'dimensionOriginal', dimensionComputed) : dimensionComputed,
            overflowStyle: get(nodeDataAnimate, 'overflowStyle', getAttrStyle(node, 'overflow'))
        };
        data(node, 'data-reef-animate', dataAnimate);

        // Adjust Speed
        speed *= parseInt(dimensionComputed.height, 10) / parseInt(dataAnimate.dimensionOriginal.height, 10);

        // Set Animation Values
        Tweener.set(node, {
            overflow: 'hidden'
        });

        // Tween
        let tween = Tweener.to(node, speed, {
            height: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0,
            ease: ease,
            onStart: () => {

                // On Start
                options.onStart.apply(this, arguments);
            },
            onUpdate: () => {

                // On Update
                options.onUpdate.apply(this, arguments);
            },
            onComplete: () => {

                // Hide Node
                hide(node);

                // On Complete
                options.onComplete.apply(this, arguments);

                // Save data animate
                dataAnimate.animating = false;
                dataAnimate.animation = '';
                data(node, 'data-reef-animate', dataAnimate);

                // Reset Animation Values
                Tweener.set(node, {
                    height: dataAnimate.dimensionStyle.height,
                    paddingTop: dataAnimate.dimensionStyle.padding.top,
                    paddingBottom: dataAnimate.dimensionStyle.padding.bottom,
                    marginTop: dataAnimate.dimensionStyle.margin.top,
                    marginBottom: dataAnimate.dimensionStyle.margin.bottom,
                    overflow: dataAnimate.overflowStyle
                });

                // Resolve Promise
                resolve();
            }
        });

        // Tween - Progress
        if (progress > 0) {
            tween.progress(1);
        }
    });
};
