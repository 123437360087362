const uid = '_reef' + Date.now();

/*
 * Data storage for setData() & getData().
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 */
export default function (node) {
    return (node[uid] = node[uid] || {});
};
