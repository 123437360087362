/*
 * Test if a window size matches the specified Media Query.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} mq
 *
 * @returns {boolean}
 */
export default function (mq) {
    return window.matchMedia(mq).matches;
}
