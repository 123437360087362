/*
 * Return the top and left position of an element relative to the document.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 *
 * @returns {Object}
 */
export default function (node) {

    const rect = node.getBoundingClientRect();
    return {
        top: rect.top + (window.pageYOffset || document.documentElement.scrollTop),
        left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft)
    };
}
