import getAttrStyle from './getAttrStyle';

/*
 * Get a Dimension Style
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 *
 * @returns {Object}
 */
export default function (node) {

    const height = getAttrStyle(node, 'height');
    const paddingTop = getAttrStyle(node, 'padding-top');
    const paddingBottom = getAttrStyle(node, 'padding-bottom');
    const marginTop = getAttrStyle(node, 'margin-top');
    const marginBottom = getAttrStyle(node, 'margin-bottom');

    return {
        height: height,
        padding: {
            top: paddingTop,
            bottom: paddingBottom
        },
        margin: {
            top: marginTop,
            bottom: marginBottom
        }
    };
};
