import select from './select';
import isString from '../../helpers/lang/isString';

/*
 * Select matching Element from the DOM and return it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|NodeList|string} node
 * @param {Node|string} context
 *
 * @returns {Node|null}
 */
export default function (node, context) {

    // Node provided
    if (node) {

        // Node is a string
        if (isString(node)) {
            node = (select(context) || document).querySelector(node);

        // Node is the document or window
        } else if (node === document) {
            node = document;

        // Node is the window
        } else if (node === window) {
            node = window;

        // NodeList, get the first one only
        } else if (!node.tagName) {
            node = node[0];
        }

        return node;
    }

    return null;
}
