/*
 * Execute a callback function when DOM is loaded & ready.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {function} callback
 */
export default function (callback) {
    if (document.readyState !== 'loading') {
        callback();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', callback);
    } else {
        document.attachEvent('onreadystatechange', () => {
            if (document.readyState !== 'loading') {
                callback();
            }
        });
    }
}
