import offset from './offset';

/*
 * Get the offset position of a node relative to its parent.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 * @param {Node} nodeParent
 *
 * @returns {Object}
 */
export default function (node, nodeParent) {

    // Offsets for node & parent
    const offsetNode = offset(node);
    const offsetNodeParent = offset(nodeParent);

    // Node & Parent Exist
    return node && nodeParent ? {
        top: offsetNode.top - offsetNodeParent.top,
        left: offsetNode.left - offsetNodeParent.left
    } : null;
}
