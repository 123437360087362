"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Generate a hash code based on a string
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} str
 *
 * @returns {number}
 */
function default_1(str) {
    var hash = 5381;
    var i = str.length;
    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }
    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
    * integers. Since we want the results to be always positive, convert the
    * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0;
}
exports.default = default_1;
