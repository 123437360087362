import select from '../select/select';

/*
 * Test if an node has the specified class.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {string} className
 * @param {Node} context
 *
 * @returns {boolean|null}
 */
export default function (node, className, context) {

    // Select matching node
    node = select(node, context);

    // DOM node exists, check if it has the class
    if (node) {
        return node.classList ? node.classList.contains(className) : !!node.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
    }

    return null;
}
