import select from '../../select/select';
import selectAll from '../../select/selectAll';
import each from '../../../helpers/collection/each';
import isString from '../../../helpers/lang/isString';

/*
 * Trigger an event on the specified node. Make sure a polyfill is used in order to
 * maximise the compatibility with older browsers.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {Node|string|Array} nodes
 * @param {string} name
 * @param {Object} data
 * @param {boolean} bubbles
 * @param {boolean} cancelable
 */
export default function (nodes, name, data, bubbles, cancelable) {

    // Cannot be dispatched
    if(!document || !isString(name)){
        return false;
    }

    // Trigger event to chosen nodes
    each(selectAll(nodes), node => {

        // Create the event
        const event = new CustomEvent(name, {
            detail: data,
            bubbles: bubbles || false,
            cancelable: cancelable || false
        });

        node.dispatchEvent(event);
    });
};
