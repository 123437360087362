import select from '../select/select';

/*
 * Get Attribute from a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {string} attribute
 *
 * @returns {*|null}
 */
export default function (node, attribute) {

    // Select matching node
    node = select(node);

    // DOM node exists, get attr value
    return node ? node.getAttribute(attribute) : null;
}
