"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Test if a value is a number.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function default_1(value) {
    return (typeof value === 'number');
}
exports.default = default_1;
