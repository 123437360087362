import removeListener from '../.internal/events/removeListener';

/*
 * Remove an event listener to one or several nodes.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {string|function} listener
 */
export default function (nodes, events, listener) {
    return removeListener(nodes, events, listener);
}
