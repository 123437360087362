import eventOff from './eventOff';
import eventOn from './eventOn';

/*
 * Add an event listener to one or several nodes, run it once and removes it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {function} listener
 * @param {string} registration
 */
export default function (nodes, events, listener, registration) {

    // Generate the final listener function
    let finalListener = listener;
    finalListener = function () {

        // Remove event listener after first call
        eventOff(nodes, events, registration || finalListener);

        // Execute listener function
        listener.apply(this, arguments);
    };

    // Add event listener
    return eventOn(nodes, events, finalListener, registration);
}
