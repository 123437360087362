import select from '../select/select';

/*
 * Get Html for a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 *
 * @returns {string|null}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // DOM node exists, set text value
    return node ? node.innerHTML : null;
}
