import disableScroll from 'disable-scroll';
import isScrollLocked from './isScrollLocked';
import set from '../../helpers/object/set';
import Reef from '../../Reef';

/*
 * Enable/Disable scrolling.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {boolean} lock
 */
export default function (lock) {

    // Check if scroll is locked
    const isLocked = isScrollLocked();

    // Lock
    if (lock && !isLocked) {
        set(Reef.storage, 'lock', true);
        disableScroll.on();

    // Unlock
    } else if (!lock && isLocked) {
        set(Reef.storage, 'lock', false);
        disableScroll.off();
    }
}
