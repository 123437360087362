import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import EventsManager from './EventsManager';
import scrollPosition from '../fn/browser/scrollPosition';
import setUndefined from '../helpers/object/setUndefined';

gsap.registerPlugin(ScrollTrigger);

/*
 * Dispatch events to the document node when scrolling events occur. Triggered
 * events are: `scrolling`, `scrollDown`, `scrollDownIntent`, `scrollUp`,
 * `scrollUpIntent`, `scrollStop` & `scrollStart`
 *
 * Powered by GSAP (https://greensock.com)
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const ScrollEvents = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: ScrollEvents.SELECTOR,
        refreshRate: ScrollEvents.REFRESH_RATE,
        threshold: ScrollEvents.THRESHOLD
    });

    // Element & Options
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
ScrollEvents.prototype.init = function () {
    const that = this;

    // Scroll
    let scrollInterval = null;
    let position = scrollPosition().y;
    let positionPrevious = position;
    let direction = '';
    let directionPrevious = '';
    let thresholdUp = null;
    let thresholdDown = null;

    // Scroll has started
    ScrollTrigger.addEventListener('scrollStart', () => {
        position = scrollPosition().y;
        positionPrevious = position;
        direction = '';
        directionPrevious = direction;
        thresholdUp = null;
        thresholdDown = null;

        // Trigger - Start
        that.events.trigger(that.el, 'scrollStart', {
            position: position
        });

        // Start scrolling interval
        scrollInterval = setInterval(() => {
            position = scrollPosition().y;

            // Trigger - Scrolling
            that.events.trigger(that.el, 'scrolling', {
                position: position
            });

            // Direction
            if (position > positionPrevious) {
                direction = 'down';
            } else if (position < positionPrevious) {
                direction = 'up';
            }

            // New direction
            if (direction !== directionPrevious) {

                // Trigger - Scroll Down
                if (direction === 'down') {
                    that.events.trigger(that.el, 'scrollDown', {
                        position: position
                    });
                    thresholdDown = position + that.options.threshold;

                // Trigger - Scroll Up
                } else if (direction === 'up') {
                    that.events.trigger(that.el, 'scrollUp', {
                        position: position
                    });
                    thresholdUp = position - that.options.threshold;
                }
            }

            // Trigger - Scroll Down Intent
            if (direction === 'down' && thresholdDown && position > thresholdDown) {
                that.events.trigger(that.el, 'scrollDownIntent', {
                    position: position
                });
                thresholdDown = null;

            // Trigger - Scroll Up Intent
            } else if (direction === 'up' && thresholdUp && position < thresholdUp) {
                that.events.trigger(that.el, 'scrollUpIntent', {
                    position: position
                });
                thresholdUp = null;
            }

            // Update
            positionPrevious = position;
            directionPrevious = direction;

        }, that.options.refreshRate);
    });

    // Scroll has ended
    ScrollTrigger.addEventListener('scrollEnd', () => {
        position = scrollPosition().y;

        // Trigger - End
        that.events.trigger(that.el, 'scrollEnd', {
            position: position
        });

        // Stop scrolling interval
        clearInterval(scrollInterval);
    });
};

/**
 * Destroy
 */
ScrollEvents.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
ScrollEvents.SELECTOR = '.js-scrollEvents';
ScrollEvents.REFRESH_RATE = 100;
ScrollEvents.THRESHOLD = 48;

export default ScrollEvents;
