"use strict";
/*
 * Parse an Array, very fast. Inspired by forEach()'s from lodash
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Array} array
 * @param {function|boolean} iteratee
 *
 * @returns {Array}
 */
Object.defineProperty(exports, "__esModule", { value: true });
var eachArray = function (arr, iteratee) {
    var index = -1;
    var length = arr == null ? 0 : arr.length;
    while (++index < length) {
        if (iteratee(arr[index], index, arr) === false) {
            break;
        }
    }
    return arr;
};
exports.default = eachArray;
