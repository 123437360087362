import Reef from '../Reef';
import data from '../fn/data/data';
import merge from '../helpers/object/merge';
import ScrollEvents from '../utilities/ScrollEvents';

/*
 * Plugin loader for ScrollEvents
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

(reef => {

    /**
     * Main
     *
     * @param {Object} options
     */
    reef.plugins.scrollEvents = function (options) {

        // Options
        options = merge(reef.plugins.scrollEvents.defaults, options);

        // Init
        if (!data(document, options.selector)) {
            data(document, options.selector, new ScrollEvents(document, options));
        }
    };

    /**
     * Defaults
     */
    reef.plugins.scrollEvents.defaults = {
        selector: ScrollEvents.SELECTOR
    };

})(Reef);
