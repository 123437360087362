import trigger from '../.internal/events/trigger';

/*
 * Trigger an event on the specified node. Make sure a polyfill is used in order to
 * maximise the compatibility with older browsers.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {Node|string|Array} nodes
 * @param {string} name
 * @param {Object} data
 * @param {boolean} bubbles
 * @param {boolean} cancelable
 */
export default function (nodes, name, data, bubbles, cancelable) {
    trigger.apply(this, arguments);
}
