import setAttr from './setAttr';
import getAttr from './getAttr';
import isNull from '../../helpers/lang/isNull';
import isUndefined from '../../helpers/lang/isUndefined';

/*
 * Set/Get Attribute from a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {string} attribute
 * @param {*} value
 *
 * @returns {*|null|undefined}
 */
export default function (node, attribute, value) {
    return (!isNull(value) && !isUndefined(value) ? setAttr(node, attribute, value) : getAttr(node, attribute));
}
