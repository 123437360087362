import isFunction from '../lang/isFunction';

/*
 * Execute a function & apply its arguments.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {function} fct
 * @param {*} ...args
 */
export default function (fct, ...args) {
    if (isFunction(fct)) {
        fct.apply(this, args);
    }
}
