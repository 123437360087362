import attrOption from './attrOption';
import get from '../../../helpers/object/get';

/*
 * Get options from attr values.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} el
 * @param {string} name
 * @param {string} selector
 * @param {*} defaultValue
 *
 * @returns {*}
 */
export default function (el, name, selector, defaultValue) {
    return get(attrOption({}, el, [name], selector), name, defaultValue);
};
