import getAttrStyle from './.internal/getAttrStyle';
import isVisible from '../../../fn/attributes/isVisible';
import show from '../../../fn/attributes/show';
import data from '../../../fn/data/data';
import get from '../../../helpers/object/get';
import Tweener from '../../../utilities/Tweener';

/*
 * Fade In animation for a node. Similar to the jQuery fadeIn() function. This
 * is part of the animate methods and returns a Promise. animate() must be called
 * prior to calling this function.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 * @param {Object} nodeDataAnimate
 * @param {boolean} animating
 * @param {number} speed
 * @param {function} ease
 * @param {number} progress
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (node, nodeDataAnimate, animating, animation, speed, ease, progress, options) {
    return new Promise(resolve => {

        // Node is already animating, kill previous animation
        if (animating) {
            Tweener.kill(node, false, { opacity: true });
        }

        // Opacity
        const opacityComputed = (window.getComputedStyle ? getComputedStyle(node) : node.currentStyle).opacity;
        const opacityStyle = getAttrStyle(node, 'opacity');

        // Current data animate
        let dataAnimate = {
            animating: true,
            animation: 'fadeIn',
            opacityStyle: get(nodeDataAnimate, 'opacityStyle', opacityStyle),
            opacityOriginal: get(nodeDataAnimate, 'opacityOriginal', opacityStyle)
        };
        data(node, 'data-reef-animate', dataAnimate);

        // Opacity - Start
        const opacityStart = animating ? opacityComputed : 0;

        // Opacity - End
        const opacityEnd = dataAnimate.opacityOriginal !== '' ? dataAnimate.opacityOriginal : opacityComputed;

        // Set Animation Values
        node.style.opacity = opacityStart;

        // Show if not already visible
        if (!isVisible(node)) {
            show(node);
        }

        // Adjust Speed
        speed *= (parseFloat(opacityEnd) - parseFloat(opacityStart)) / parseFloat(opacityEnd);

        // Tween
        let tween = Tweener.to(node, speed, {
            opacity: opacityEnd,
            ease: ease,
            onStart: () => {

                // On Start
                options.onStart.apply(this, arguments);
            },
            onUpdate: () => {

                // On Update
                options.onUpdate.apply(this, arguments);
            },
            onComplete: () => {

                // Reset Animation Values
                node.style.opacity = dataAnimate.opacityStyle;

                // On Complete
                options.onComplete.apply(this, arguments);

                // Save Animate Data
                dataAnimate.animating = false;
                dataAnimate.animation = '';
                delete dataAnimate.opacityStyle;
                delete dataAnimate.opacityOriginal;
                data(node, 'data-reef-animate', dataAnimate);

                // Resolve Promise
                resolve();
            }
        });

        // Tween - Progress
        if (progress > 0) {
            tween.progress(1);
        }
    });
};
