import getDefaultStyle from './getDefaultStyle';
import data from '../../data/data';

/*
 * Show/Hide Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 * @param {boolean} show
 */
export default function (node, show) {

    let value = data(node, 'data-reef-attributes-display');
    const display = node.style.display;
    const computedDisplay = (window.getComputedStyle ? getComputedStyle(node, null) : node.currentStyle).display;

    if (show) {
        if (!value && display === 'none') {
            node.style.display = '';
        }
        if (node.style.display === '' && (computedDisplay === 'none')) {
            value = value || getDefaultStyle(node.nodeName).display;
        }
    } else {
        if (display && display !== 'none' || !(computedDisplay == 'none')) {
            data(node, 'data-reef-attributes-display', (computedDisplay == 'none') ? display : computedDisplay);
        }
    }
    if (!show || node.style.display === 'none' || node.style.display === '') {
        node.style.display = show ? value || '' : 'none';
    }
};
