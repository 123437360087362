import eventOff from '../fn/events/eventOff';
import eventOn from '../fn/events/eventOn';
import eventOnce from '../fn/events/eventOnce';
import eventTrigger from '../fn/events/eventTrigger';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import arrayMerge from '../helpers/array/arrayMerge';
import inArray from '../helpers/array/inArray';
import isString from '../helpers/lang/isString';
import random from '../helpers/string/random';

/*
 * Easily manage a large number of events. Eventlisteners can be added to the
 * manager and then destroyed on request. All registered events can be destroyed at
 * the same time. This is very useful to manage responsive layouts. It can handle
 * all sorts of event from triggered event to resize event and even window resize
 * events.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @constructor
 *
 * @returns {this}
 */
const EventsManager = function () {
    const that = this;

    // Arrays of events
    that.events = [];

    // Enable chaining
    return that;
};

/**
 * Create an event listener for 1 or several nodes
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {function} listener
 * @param {string} registration
 *
 * @returns {this}
 */
EventsManager.prototype.on = function (nodes, events, listener, registration) {
    const that = this;

    // Add to array of events
    that.events = arrayMerge(that.events, eventOn(nodes, events, listener, registration || random(50)));

    return that;
};

/**
 * Create an event listener for 1 or several nodes that can only be executed once
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {function} listener
 * @param {string} registration
 *
 * @returns {this}
 */
EventsManager.prototype.once = function (nodes, events, listener, registration) {
    const that = this;

    // Add to array of events
    that.events = arrayMerge(that.events, eventOnce(nodes, events, listener, registration || random(40)));

    return that;
};

/**
 * Remove an event listener based on the node and the name of the event
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {string} registration
 *
 * @returns {this}
 */
EventsManager.prototype.off = function (nodes, events, registration) {
    const that = this;

    // Make an array of events in order to remove several events at once
    events = isString(events) ? events.split(' ') : events;

    // Save events to remove as an array
    const eventsToRemove = [];
    const eventsToRemain = [];

    // Parse nodes & remove check if event must be removed
    each(selectAll(nodes), node => {
        each(that.events, event => {

            // Remove event ?
            let removeEvent = false;

            // Node found
            if (event.node === node) {

                // Event name must be removed ?
                if (events) {
                    if (inArray(events, event.event)) {
                        removeEvent = true;
                    }
                } else {
                    removeEvent = true;
                }

                // Registration must be removed ?
                if (removeEvent) {
                    removeEvent = false;
                    if (registration) {
                        if (registration === event.registration) {
                            removeEvent = true;
                        }
                    } else {
                        removeEvent = true;
                    }
                }
            }

            // Event must be removed
            if (removeEvent) {
                eventsToRemove.push(event);
            } else {
                eventsToRemain.push(event);
            }
        });
    });

    // Remove events that need to be removed
    each(eventsToRemove, event => {
        eventOff(event.node, event.event, event.registration);
    });

    // Reassign events
    that.events = eventsToRemain;

    return that;
};

/**
 * Trigger an event
 *
 * @returns {this}
 */
EventsManager.prototype.trigger = function () {
    const that = this;

    eventTrigger.apply(that, arguments);

    return that;
};

/**
 * Destroy all registered events
 *
 * @returns {this}
 */
EventsManager.prototype.destroy = function () {
    const that = this;

    // Parse & remove all events
    each(that.events, event => {
        eventOff(event.node, event.event, event.registration);
    });

    return that;
};

export default EventsManager;
