import get from '../../helpers/object/get';
import Reef from '../../Reef';

/*
 * Is Scroll Locked ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {boolean} lock
 */
export default function () {
    return get(Reef.storage, 'lock', false);
}
