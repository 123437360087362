import Reef from '../../Reef';

/*
 * Add scroll to Reef.methods.defaults.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

Reef.methods.defaults.scroll = {
    speed:  1000,
    ease: 'power2.inOut',
    offset: 0
};
