import addListener from '../.internal/events/addListener';

/*
 * Add an event listener to one or several nodes.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {function} listener
 * @param {string} registration
 */
export default function (nodes, events, listener, registration) {
    return addListener(nodes, events, listener, registration);
}
