import '../.defaults/scroll';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import onCallback from '../.internal/callback/onCallback';
import merge from '../../helpers/object/merge';
import Reef from '../../Reef';

gsap.registerPlugin(ScrollToPlugin);

/*
 * Scroll to a specific Node
 *
 * Powered by GSAP (https://greensock.com)
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (node, options) {
    const that = this;

    // Options
    options = merge(Reef.methods.defaults.scroll, options);

    // Promise
    return new Promise(resolve => {

        const tween = gsap.to(window, { duration: options.speed / 1000, scrollTo: { y: node, offsetY: options.offset || 0 }, ease: options.ease });
        tween.eventCallback('onComplete', () => {

            // Resolve
            resolve();

            // onComplete
            onCallback.call(that, 'onComplete', options);
        });
    });
}
