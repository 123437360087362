"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Trim a string
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} str
 *
 * @returns {string}
 */
function default_1(str) {
    return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
}
exports.default = default_1;
