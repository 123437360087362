"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../.internal/object/Object.assign");
/*
 * Return the copied value of an object
 *
 * @author Christophe Meade
 *
 * @copyright 2019-present Christophe Meade
 *
 * @param {Object} obj
 *
 * @returns {Object}
 */
function default_1(obj) {
    return Object.assign({}, obj);
}
exports.default = default_1;
