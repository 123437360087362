import gsap from 'gsap';
import select from '../fn/select/select';
import each from '../helpers/collection/each';
import isArray from '../helpers/lang/isArray';
import isBoolean from '../helpers/lang/isBoolean';
import copy from '../helpers/object/copy';
import isEmpty from '../helpers/object/isEmpty';
import has from '../helpers/object/has';
import set from '../helpers/object/set';
import unset from '../helpers/object/unset';

/*
 * Animation engine which is able to animate any attribute from any node in the DOM.
 * It can be used as a Timeline with many tweens to be executed at the same time or
 * as a Tween engine, to execute 1 tween at the time. Note that some eases (Linear,
 * Power0, Power1, Power2, Power3, Power4) are by defaut included in Tweenlite.
 * However other eases (Back, Bounce, Circ, Elastic, Expo, ExpoScaleEase, Sine,
 * RoughEase, SlowMo, SteppedEase) must be imported somewhere in the project in
 * order to be used.
 *
 * Powered by GSAP (https://greensock.com)
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @constructor
 *
 * @param {Object} options
 *
 * @returns {this}
 */
const Tweener = function (options) {
    const that = this;

    // Create default values
    options = options || {};

    let defaults = copy(options);
    if (options.speed) {
        defaults.duration = options.speed / 1000;
    }
    if (options.delay) {
        defaults.delay = options.delay / 1000;
    }
    defaults = unset(defaults, 'onStart', 'onComplete', 'speed');

    // Sometimes some transform animations would get blurry. Deactivating the 3D
    // support solves that bug
    gsap.config({
        force3D: false
    });

    // Create a timeline paused by default. A ScrollTrigger can be added to the
    // animation for parallax effect
    that.timeline = options.scrollTrigger ? gsap.timeline({ scrollTrigger: options.scrollTrigger }) : gsap.timeline({ paused: true, defaults: defaults });

    // onStart
    if (options.onStart) {
        that.timeline.eventCallback('onStart', options.onStart);
    }

    // onComplete
    if (options.onComplete) {
        that.timeline.eventCallback('onComplete', options.onComplete);
    }

    // Array to save tweened elements & properties
    that.tweens = [];

    return that;
};

/**
 * Add a tween to the timeline
 *
 * @param {Node|string|Array} el
 * @param {string} property
 * @param {Object} options
 * @param {boolean} addToEnd
 * @param {boolean} killTweens
 */
Tweener.prototype.addTween = function (el, property, options, addToEnd, killTweens) {
    const that = this;

    // Array of elements
    if (isArray(el)) {
        each(el, e => {
            that.addTween(e, property, options, addToEnd, killTweens);
        });

    // Single element
    } else {

        // Select matching element
        el = select(el);

        // Kill previous tweens
        if (killTweens) {
            if (isBoolean(killTweens)) {
                gsap.killTweensOf(el);
            } else {
                gsap.killTweensOf(el, killTweens);
            }
        }

        // Add to tweened properties
        that.tweens.push({ el: el, property: property });

        // Position in the timeline. By default the tween is added at the beginning
        const position = addToEnd || false ? '+=0' : 0;

        // From
        const optionsFrom = {};
        if (has(options, 'from')) {
            set(optionsFrom, property, options.from);
        }

        // To
        const optionsTo = {};
        if (has(options, 'to')) {
            set(optionsTo, property, options.to);
        }

        // To - Ease
        if (options.ease) {
            optionsTo.ease = options.ease;
        }

        // To - Delay (ms)
        if (options.delay) {
            optionsTo.delay = options.delay / 1000;
        }

        // Speed
        if (options.speed) {
            optionsTo.duration = options.speed / 1000;
        }

        // From & To
        if (!isEmpty(optionsFrom) && !isEmpty(optionsTo)) {
            that.timeline.fromTo(el, optionsFrom, optionsTo, position);

        // From
        } else if (!isEmpty(optionsFrom)) {
            that.timeline.from(el, optionsFrom, position);

        // To
        } else if (!isEmpty(optionsTo)) {
            that.timeline.to(el, optionsTo, position);
        }
    }

    return that;
};

/**
 * Add several tweens to the timeline
 *
 * @param {Node|string} el
 * @param {Object} properties
 * @param {boolean} addToEnd
 * @param {boolean} killTweens
 */
Tweener.prototype.addTweens = function (el, properties, addToEnd, killTweens) {
    const that = this;

    // Array of elements
    if (isArray(el)) {
        each(el, e => {
            that.addTweens(e, properties, addToEnd, killTweens);
        });

    // Single element
    } else {

        // Kill previous tweens
        if (killTweens) {
            if (isBoolean(killTweens)) {
                gsap.killTweensOf(el);
            } else {
                gsap.killTweensOf(el, killTweens);
            }
        }

        // Add tweens
        each(properties, (options, property) => {
            that.addTween(el, property, options, addToEnd);
        });
    }

    return that;
};

/**
 * Function shortcut for addTweens with the most commen values
 *
 * @param {Node|string} el
 * @param {Object} properties
 */
Tweener.prototype.add = function (el, properties) {
    const that = this;

    that.addTweens(el, properties, false, true);

    return that;
};

/**
 * Add a progression tween to the element. This is usefull in case no CSS attribute
 * needs to be tweened. It acts like a timer for which it is possible to retrieve
 * the progression
 *
 * @param {Node|string} el
 * @param {Object} options
 */
Tweener.prototype.addProgress = function (el, options = {}) {
    const that = this;

    // Array of elements
    if (isArray(el)) {
        each(el, e => {
            that.addProgress(e, options);
        });

    // Single element
    } else {

        // Select matching element
        el = select(el);

        // Object To
        const optionsFrom = { progress: 0 };
        const optionsTo = { progress: 1 };

        // To - Ease
        if (options.ease) {
            optionsTo.ease = options.ease;
        }

        // To - Delay (ms)
        if (options.delay) {
            optionsTo.delay = options.delay / 1000;
        }

        // Speed
        if (options.speed) {
            optionsTo.duration = options.speed / 1000;
        }

        // Add to timeline
        that.timeline.to(optionsFrom, optionsTo);
    }

    return that;
};

/**
 * Override the 'onStart' event callback
 *
 * @param {function} onStart
 */
Tweener.prototype.onStart = function (onStart) {
    const that = this;

    that.timeline.eventCallback('onStart', onStart);

    return that;
};

/**
 * Override the 'onStart' event callback
 *
 * @param {function} onUpdate
 */
Tweener.prototype.onUpdate = function (onUpdate) {
    const that = this;

    that.timeline.eventCallback('onUpdate', onUpdate);

    return that;
};

/**
 * Override the 'onComplete' event callback
 *
 * @param {function} onComplete
 */
Tweener.prototype.onComplete = function (onComplete) {
    const that = this;

    that.timeline.eventCallback('onComplete', () => {
        onComplete();
    });

    return that;
};

/**
 * Play the animation
 *
 * @param {function} onComplete
 *
 * @returns {Promise}
 */
Tweener.prototype.play = function (onComplete) {
    const that = this;

    // If a function is specified, it will be executed whenever the tween has
    // completed
    if (onComplete) {
        that.onComplete(onComplete);
    }

    // Play timeline
    return that.timeline.play();
};

/**
 * Reverse the animation (play backwards)
 */
Tweener.prototype.reverse = function () {
    this.timeline.reverse();
};

/**
 * Update the progression of the animation
 *
 * @param {number} progress
 */
Tweener.prototype.progress = function (progress) {
    this.timeline.progress(progress);
};

/**
 * Update the progression of the animation
 */
Tweener.prototype.getProgress = function () {
    return this.timeline.progress();
};

/**
 * Pause the timeline
 *
 * @param {boolean} status
 */
Tweener.prototype.pause = function (status) {
    const that = this;

    that.timeline.paused(status);
};

/**
 * Stop the timeline & suppress callback events
 */
Tweener.prototype.stop = function () {
    const that = this;

    // Suppress events
    that.timeline.eventCallback('onComplete', null);
    that.timeline.eventCallback('onUpdate', null);
    that.timeline.eventCallback('onStart', null);
    that.timeline.eventCallback('onReverseComplete', null);
    that.timeline.eventCallback('onRepeat', null);

    // Pause
    that.timeline.pause(null, true);
};

/**
 * Immediately stops the animation and releasing it for garbage collection
 */
Tweener.prototype.kill = function () {
    const that = this;

    if (that.timeline) {
        that.timeline.kill();
        that.timeline = null;
    }
};

/**
 * Kill tweens
 */
Tweener.kill = function () {
    gsap.killTweensOf.apply(this, arguments);
};

/**
 * Set CSS properties
 *
 * @param {Node|Array} el
 * @param {Object} props
 * @param {boolean} kill
 */
Tweener.set = function (el, props, kill = false) {

    // Array of elements
    if (isArray(el)) {
        each(el, e => {
            if (kill) {
                gsap.killTweensOf(e);
            }
            gsap.set(e, props);
        });

    // Single element
    } else {
        if (kill) {
            gsap.killTweensOf(el);
        }
        gsap.set(el, props);
    }
};

/**
 * Get the current CSS properties
 *
 * @param {Node|Array} el
 * @param {string} prop
 */
Tweener.get = function (el, prop) {
    return gsap.getProperty(el, prop);
};

/**
 * Tween `from` CSS properties
 */
Tweener.from = function () {
    gsap.from.apply(this, arguments);
};

/**
 * Tween `to` CSS properties
 */
Tweener.to = function () {
    gsap.to.apply(this, arguments);
};

/**
 * Clear CSS properties
 *
 * @param {Node|Array} el
 * @param {string|Array} props
 */
Tweener.clear = function (el, props) {

    // Array of elements
    if (isArray(el)) {
        each(el, e => {
            Tweener.set(e, { clearProps: (isArray(props) ? props.join(',') : props) });
        });

    // Single element
    } else {
        Tweener.set(el, { clearProps: (isArray(props) ? props.join(',') : props) });
    }
};

export default Tweener;
