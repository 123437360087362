import serialize from 'form-serialize';
import select from '../select/select';

/*
 * Returns the form data as an object or a string.
 *
 * Powered by https://github.com/defunctzombie/form-serialize
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {string} format ('json', 'qs')
 *
 * @returns {Object|string|null}
 */
export default function (node, format) {

    // Select matching node
    node = select(node);

    // DOM node exists, set attr value
    if (node) {

        // Format as a query string
        if (format && format === 'qs') {
            return serialize(node, { empty: true, disabled: true });
        }

        // Format as a Json
        return serialize(node, { hash: true, empty: true, disabled: true });
    }

    return null;
}
