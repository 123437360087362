import reqwest from 'reqwest';
import onCallback from '../.internal/callback/onCallback';
import serialize from '../../fn/form/serialize';
import isObject from '../../helpers/lang/isObject';
import isString from '../../helpers/lang/isString';
import get from '../../helpers/object/get';
import set from '../../helpers/object/set';
import parseJson from '../../helpers/string/parseJson';
import startsWith from '../../helpers/string/startsWith';

/*
 * Perform an AJAX Request (POST/GET).
 *
 * Powered by https://github.com/ded/reqwest
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} url
 * @param {string} method (POST, GET)
 * @param {Object|string} data
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (url, method, data, options) {
    const that = this;

    return new Promise((resolve, reject) => {

        // Data can be an urlencoded string, an object or a reference to a node. If data
        // is a node id, the form needs to be serialized
        if (data && isString(data) && startsWith(data, '#')) {
            data = serialize(data);
        }

        options = options || {};

        // A requestID is provided, therefore it needs to be added to the final
        // result when available if the output is JSON
        const ajaxRequestID = get(options.ajaxRequestID, '');

        // Output mode
        const output = get(options.output, '');

        // Create the request
        const request = {
            url: url,

            // Method, can be GET or POST
            method: (method || 'get').toLowerCase(),

            // Data to be sent along with the request, can be a string or an object
            data: data || {},

            // On Success
            success: response => {
                const success = output === 'json' ? parseJson(response) : response;

                // Re-inject request ID to the final result
                if (ajaxRequestID !== '' && isObject(success)) {
                    set(success, 'ajaxRequestID', ajaxRequestID);
                }

                // Callback
                onCallback.call(that, 'onComplete', options);
                onCallback.call(that, 'onSuccess', options, success);
                resolve(success);
            },

            // On Error
            error: response => {
                let error = output === 'json' ? parseJson(response.response) : response.response;

                // Re-inject request ID to the final result
                if (ajaxRequestID !== '' && isObject(error)) {
                    set(error, 'ajaxRequestID', ajaxRequestID);
                }

                // Create error object
                error = {
                    status: response.status,
                    message: response.statusText,
                    response: error
                };

                // Callback
                onCallback.call(that, 'onError', options, error);
                reject(error);
            }
        };

        // Options specified
        if (options) {

            // Type can be html, xml, json or jsonp
            if (options.type && options.type !== 'script') {
                request.type = options.type;
            }

            // Mime-type for the request. Eg: application/json
            if (options.contentType) {
                request.contentType = options.contentType;
            }
        }

        // Ajax
        reqwest(request);
    });
}
